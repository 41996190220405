/** @jsx jsx */
import React from "react"

import { jsx, Flex, Text } from "theme-ui"

import Icon from "./icon"

const iconStyle = {
  height: "20px",
  fill: "gray-500",
  alignSelf: "baseline",
}

const BedBath = ({ apartment }) => (
  <Flex
    sx={{
      mt: "auto",
      mb: 2,
      py: 2,
      fontSize: 2,
      color: "gray",
      flexWrap: "wrap",
    }}
  >
    <Flex sx={{ pr: 3 }}>
      <Icon icon="bed" sx={{ ...iconStyle }} />
      <Text sx={{ pl: 1 }}>
        {apartment.beds} bedroom{apartment.beds !== 1 ? "s" : ""}
      </Text>
    </Flex>
    <Flex>
      <Icon icon="bath" sx={{ ...iconStyle }} />
      <Text sx={{ pl: 1 }}>
        {apartment.baths} bathroom{apartment.baths !== 1 ? "s" : ""}
      </Text>
    </Flex>
  </Flex>
)

export default BedBath
