/** @jsx jsx */

import { Input, jsx } from "theme-ui"

const StyledInput = ({ disabled = false, sx, ...rest }) => (
  <Input
    {...rest}
    sx={{
      bg: disabled ? "gray-200" : null,
      ...sx,
    }}
    disabled={disabled}
  />
)

export default StyledInput
