/** @jsx jsx */
import { useEffect, useState, Children } from "react"
import { jsx, Box, Flex } from "theme-ui"
import Swiper from "swiper"
import "swiper/css/swiper.min.css"

const Carousel = ({
  children,
  initialSlide = 0,
  height = "400px",
  handleClick,
}) => {
  const [swiper, setSwiper] = useState()
  useEffect(() => {
    setSwiper(
      new Swiper(".swiper-container", {
        // Optional parameters
        direction: "horizontal",
        initialSlide,

        // If we need pagination
        pagination: {
          el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      })
    )
  }, [initialSlide])

  return (
    <Box className="swiper-container" sx={{ width: "100%", height }}>
      <Flex className="swiper-wrapper">
        {Children.map(children, (child) => {
          return (
            <Box
              className="swiper-slide"
              onClick={() => handleClick(swiper.realIndex)}
            >
              <Flex sx={{ justifyContent: "center", height: "100%" }}>
                {child}
              </Flex>
            </Box>
          )
        })}
      </Flex>
      <div className="swiper-pagination"></div>

      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
    </Box>
  )
}

export default Carousel
