/** @jsx jsx */
import { useState, useRef } from "react"
import {
  Heading,
  Flex,
  Image,
  Message,
  Text,
  Box,
  Badge,
  jsx,
  useThemeUI,
  Button,
  NavLink,
} from "theme-ui"
import { DialogOverlay, DialogContent } from "@reach/dialog"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Amenities from "../components/amenities"
import Carousel from "../components/carousel"
import ShowingRequestForm from "../components/showing-request-form"

import "@reach/dialog/styles.css"

const IMAGE_HEIGHT = "400px"

const formatAvailableDate = (dateStr) => {
  return new Date(dateStr).toLocaleDateString("default", {
    month: "long",
    year: "numeric",
    timeZone: "utc",
  })
}

const Detail = ({ title, text }) => {
  return (
    <Flex sx={{ alignItems: "baseline" }}>
      <Text
        sx={{
          fontSize: 0,
          color: "gray-600",
          pr: 2,
          fontWeight: "bold",
        }}
      >
        {title}
      </Text>
      <Text sx={{ fontWeight: 500, fontSize: 4 }}>{text}</Text>
    </Flex>
  )
}

const ApartmentDetail = ({ pageContext }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [dialogImage, setDialogImage] = useState()
  const showingFormEl = useRef()
  const { theme } = useThemeUI()
  const { breakpoints } = theme
  const iBreakpoints = breakpoints.map((breakpoint) =>
    parseInt(breakpoint.split("px")[0])
  )

  const handleImageClick = (index) => {
    // disable dialog on small screens
    if (window.innerWidth <= iBreakpoints[0]) {
      return
    }

    setDialogImage(pageContext.image[index])
    setShowDialog(true)
  }

  const scrollShowingFormIntoView = (e) => {
    showingFormEl.current.scrollIntoView({ behavior: "smooth" })
  }

  const close = () => setShowDialog(false)
  const title = `${pageContext.address} #${pageContext.unit}`
  const hasImages = pageContext.image.length
  const hideRent = pageContext.rent === 0 || pageContext.hide_rent

  return (
    <Layout>
      <SEO title={title} image={pageContext.property.cover_photo.publicURL} />
      <Flex sx={{ flexFlow: "column wrap", mt: [4] }}>
        <Heading as="h1" sx={{ mb: 2 }}>
          {title}
        </Heading>
        <Heading sx={{ fontSize: 2, mb: 4, color: "gray-700" }}>
          {pageContext.property.title}
        </Heading>
        {!pageContext.available && (
          <Message variant="error" sx={{ mb: 4 }}>
            This apartment is not currently available for rent
          </Message>
        )}

        <Image src={pageContext.property.cover_photo.publicURL} />

        <Flex sx={{ py: 4, flexFlow: "wrap" }}>
          <Flex sx={{ mr: [0, 5], pr: 3, flexFlow: "column" }}>
            {pageContext.available && (
              <Box>
                <Badge sx={{ fontSize: 1 }}>Available for rent!</Badge>
              </Box>
            )}
            <Detail
              title="RENT"
              text={
                hideRent
                  ? "Contact us"
                  : `$${pageContext.rent.toLocaleString()}`
              }
            />
            <Detail title="ADDRESS" text={pageContext.address} />
            <Detail
              title="SQFT"
              text={pageContext.sqft ? pageContext.sqft.toLocaleString() : `--`}
            />
            {pageContext.available_date && (
              <Detail
                title="AVAILABLE"
                text={formatAvailableDate(pageContext.available_date)}
              />
            )}
          </Flex>

          <Flex sx={{ flexDirection: "column", my: 3, alignSelf: "baseline" }}>
            <Amenities apartment={pageContext} />
          </Flex>
        </Flex>

        <Flex sx={{ alignItems: "center", mb: [4], flexWrap: "wrap" }}>
          <Button
            variant="primary"
            sx={{ mr: [5], mb: [3, 0] }}
            onClick={scrollShowingFormIntoView}
          >
            Schedule a showing
          </Button>
          <NavLink href="/apply" target="_blank">
            <Button variant="blue">Apply now</Button>
          </NavLink>
        </Flex>

        {pageContext.floorplan && (
          <Box sx={{ maxWidth: "100%" }}>
            <object
              data={`${pageContext.floorplan.publicURL}#view=FitH`}
              title="floorplan"
              width="100%"
              height="400px"
            />

            <a
              href={pageContext.floorplan.publicURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              View floorplan
            </a>
          </Box>
        )}

        <Text sx={{ mt: 4 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: pageContext.fields.description.html,
            }}
          />
        </Text>

        <Heading as="h2" sx={{ my: 4 }}>
          Photos
        </Heading>

        {hasImages ? (
          <Carousel
            height={IMAGE_HEIGHT}
            handleClick={(index) => handleImageClick(index)}
          >
            {pageContext.image.map((img, index) => (
              <Box sx={{ alignSelf: "center" }} key={index}>
                <Image
                  sx={{ maxHeight: IMAGE_HEIGHT, width: "auto" }}
                  src={img.url}
                />
              </Box>
            ))}
          </Carousel>
        ) : (
          <Text>No photos available for this unit</Text>
        )}

        <Heading as="h2" sx={{ my: 4, pt: [3] }} ref={showingFormEl}>
          Schedule a showing
        </Heading>
        <ShowingRequestForm apartment={pageContext} />
      </Flex>

      {showDialog && (
        <DialogOverlay onDismiss={close} style={{ zIndex: "1" }}>
          <DialogContent
            aria-label="image preview"
            style={{
              width: dialogImage.width,
              maxWidth: "75vw",
            }}
          >
            <Flex sx={{ justifyContent: "center" }}>
              <Image src={dialogImage.url} />
            </Flex>
          </DialogContent>
        </DialogOverlay>
      )}
    </Layout>
  )
}

export default ApartmentDetail
