/** @jsx jsx */
import { useState } from "react"
import { jsx, Label, Textarea, Button, Flex, Box, Message } from "theme-ui"

import Input from "./input"
import Spinner from "./spinner"

const ERROR_MSG = `An error occurred while submitting the form. Please try again later or contact our office.`

const ShowingRequestForm = ({ apartment }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [isSubmitting, setSubmitting] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [validationError, setValidationError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  const ValidationMsg = () =>
    validationError && (
      <Message variant="error" sx={{ mb: 3 }}>
        {validationError}
      </Message>
    )

  const submitForm = (e) => {
    e.preventDefault()
    if (!email && !phone) {
      setValidationError("Please fill out either email or phone.")
      return
    }

    setValidationError("")
    setSubmitting(true)

    fetch(`${process.env.GATSBY_STRAPI_URL}/showing-requests`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        apartment: apartment.id,
        message,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          setErrorMsg(ERROR_MSG)
        }
      })
      .catch((_) => {
        setErrorMsg(ERROR_MSG)
      })
      .finally(() => {
        setSubmitting(false)
        setHasSubmitted(true)
      })
  }

  return !hasSubmitted ? (
    <form onSubmit={submitForm}>
      <ValidationMsg />
      <Label htmlFor="name">Name</Label>
      <Input
        name="name"
        required
        onChange={(e) => setName(e.target.value)}
        disabled={isSubmitting}
      />
      <Flex sx={{ flexDirection: ["column", "row"] }}>
        <Box sx={{ mr: [0, 4], flexGrow: 1 }}>
          <Label htmlFor="email" sx={{ mt: 3 }}>
            Email
          </Label>
          <Input
            name="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            disabled={isSubmitting}
          />
        </Box>
        <Box>
          <Label htmlFor="phone" sx={{ mt: 3 }}>
            Phone
          </Label>
          <Input
            name="phone"
            onChange={(e) => setPhone(e.target.value)}
            disabled={isSubmitting}
          />
        </Box>
      </Flex>
      <Label htmlFor="unit" sx={{ mt: 3 }}>
        Unit
      </Label>
      <Input
        name="unit"
        disabled
        value={`${apartment.address}, #${apartment.unit}`}
      />
      <Label htmlFor="message" sx={{ mt: 3 }}>
        Message (optional)
      </Label>
      <Input
        as={Textarea}
        sx={{ resize: "none" }}
        rows={4}
        name="phone"
        onChange={(e) => setMessage(e.target.value)}
        disabled={isSubmitting}
      />
      <Button type="submit" sx={{ mt: 3 }}>
        {!isSubmitting ? "Submit" : <Spinner />}
      </Button>
    </form>
  ) : errorMsg ? (
    <Message variant="error">{errorMsg}</Message>
  ) : (
    <Message>Your request has been submitted!</Message>
  )
}

export default ShowingRequestForm
