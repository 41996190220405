/** @jsx jsx */
import React from "react"
import { Flex, jsx } from "theme-ui"
import BedBath from "./bedbath"
import Icon from "./icon"

const Amenities = ({ apartment }) => {
  return (
    <>
      <BedBath apartment={apartment} />
      {apartment.parking && (
        <Flex sx={{ color: "gray", fontSize: 2 }}>
          <Icon
            icon="car"
            sx={{
              height: "20px",
              alignSelf: "baseline",
              fill: "gray-500",
              mr: 1,
            }}
          />
          Parking available
        </Flex>
      )}
    </>
  )
}

export default Amenities
