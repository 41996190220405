import React from "react"
import Car from "../images/car.svg"
import Bed from "../images/bed.svg"
import Bath from "../images/bath.svg"
import Dog from "../images/dog.svg"

const getIcon = (icon) => {
  const iconDescriptor = icon.toLowerCase()
  switch (iconDescriptor) {
    case "car":
      return Car
    case "bed":
      return Bed
    case "bath":
      return Bath
    case "dog":
      return Dog
    default:
      return null
  }
}

const Icon = ({ icon, ...props }) => {
  const IconComponent = getIcon(icon)
  return <IconComponent {...props} />
}

export default Icon
